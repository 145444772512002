.c-home {
  background-color: var(--color-white);
  width: 100%;
  /* height: 100vh; */
  display: flex;
  align-items: center;
  /* justify-content: center; */
  padding: 0rem;
  flex-direction: column;
  gap: 4rem;
}

.c-logo-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
}

.c-logo-container img {
  width: 200px;
}

.c-hero-container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(../../images/home.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 2rem;
}
.c-hero-container p {
  /* position: absolute; */
  font-size: var(--font-s-xl);
  font-weight: bold;
}

.c-hero {
  width: 90%;
  height: 70vh;
  background-color: var(--color-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
}

.c-about-us-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5rem;
  padding: 0 2rem;
}

.c-about-us {
  width: 30%;
  height: 50vh;
  background-color: var(--color-gray);
  padding: 2rem;
  color: var(--color-black);
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.c-about-us-img {
  width: 100%;
  height: 50%;
}

.c-about-us-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.c-about-us h3 {
  font-size: var(--font-s-ml);
  font-weight: bold;
}

.c-about-us p {
  font-size: var(--font-s-s);
}

.c-contact-form-container {
  font-size: var(--font-s-xl);
  width: 100%;
  color: var(--color-black);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  flex-direction: column;
  padding: 0 2rem;
}

.c-contact-form-container form {
  width: 60%;
  max-width: 70rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.c-contact-form-container form input {
  border: 1px solid var(--color-black);
  padding: 1rem;
  margin-top: 1rem;
  width: 100%;
  color: var(--color-black);
}

.c-contact-form-container form span {
  font-size: var(--font-s-s);
}

.c-contact-form-textarea {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.c-contact-form-textarea textarea {
  border: 1px solid var(--color-black);
  padding: 1rem;
  margin-top: 1rem;
  width: 100%;
  color: var(--color-black);
  height: 30rem;
}

.c-contact-form-container button {
  font-size: var(--font-s-s);
}

.c-contact-message {
  font-size: var(--font-s-s);
  color: green;
}

.c-footer-container {
  width: 100%;
  color: var(--color-black);
  text-align: center;
  font-size: var(--font-s-s);
  display: flex;
  align-items: center;
  padding: 2rem;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  font-weight: bold;
}

.c-footer-info {
  width: 100%;
  display: flex;
  gap: 4rem;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .c-hero {
    width: 100%;
  }

  .c-about-us-container {
    flex-direction: column;
  }

  .c-about-us {
    width: 100%;
    height: 60vh;
  }

  .c-contact-form-container form {
    width: 100%;
  }

  .c-footer-info {
    flex-direction: column;
    gap: 2rem;
  }
}
