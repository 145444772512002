/* ******************************************** */
/** SETTINGS 
/* ******************************************** */
:root {
  --font: "Poppins", sans-serif;
  /* font sizes */
  --font-s-xs: 1.2rem;
  --font-s-s: 1.5rem;
  --font-s-m: 1.8rem;
  --font-s-ml: 2rem;
  --font-s-l: 2.2rem;
  --font-s-xl: 3.2rem;
  --font-s-xxl: 4.2rem;
  --font-s-xxxl: 5rem;

  --font-w-light: 100;
  --font-w-normal: 500;
  --font-w-semi: 700;
  --font-w-bold: bold;

  /* colors */
  --color-black: #3a3a3a;
  --color-white: #ffffff;
  --color-dark: #282931;
  --color-blue: #11b5e4;
  --color-gray: #f3f3f3;

  --opacity-0: 0.5;
  --opacity-1: 0.7;

  /* sizes */
  --wrapper-desktop: 1440px;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-decoration: none;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
input {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
article,
aside,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

/* ******************************************** */
/** GENERIC: SCREEN
/* ******************************************** */

/* BODY */

html {
  font-size: 10px;
  box-sizing: border-box;
}

html:not(.disable-smooth-scroll) {
  scroll-behavior: smooth;
  /* scroll-padding-top: var(--header-xs); */
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-size: 100%;
  /* background-color: var(--color-blue); */
}

body,
input,
textarea,
select,
button {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  font-family: var(--font);
  font-weight: 500;
  color: var(--color-white);
}

p {
  text-rendering: optimizeLegibility;
}

strong {
  font-weight: 700;
}

em {
  font-style: italic;
}

input,
textarea,
select,
button {
  border: none;
  outline: none;
  resize: none;
  border-radius: 0;
  background-color: transparent;
  padding: 0;
  margin: 0;
}

input:not([type="checkbox"]):not([type="radio"]),
textarea,
select,
button {
  -webkit-appearance: none;
}

button {
  overflow: visible;
  font-size: inherit;
}

a,
button {
  color: var(--color-black);
  cursor: pointer;
  outline: none;
}

img {
  display: inline-block;
  max-width: 100%;
}

.gc-wrapper {
  margin: auto !important;
  max-width: 144rem !important;
  max-height: 100vh !important;
}

.gc-hidden-for-seo {
  position: absolute;
  left: -9999px;
}
